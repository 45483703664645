import React, { useState } from 'react';
import { X } from '@phosphor-icons/react';
import Spinner from '@/components/Loader/Spinner';
import uniqid from 'uniqid';

export default function Alert({
    id = 'alert_' + uniqid(),
    title,
    text,
    type = 'success',
    dismissible = true,
    className = '',
    loading = false,
    customIcon = false,
    hideIcon = false,
    btn = false,
    children = false,
}) {
    const [show, setShow] = useState(true);
    if (!show) return;

    type = type == 'error' ? 'danger' : type;
    type = type == 'info' ? 'secondary' : type;

    let icon =
        type == 'secondary'
            ? 'Info'
            : type == 'success'
            ? 'Check---Circle'
            : type == 'danger'
            ? 'Alert---Octagon'
            : 'Info';

    const hide = () => {
        $('#' + id).slideUp(300, () => {
            setShow(false);
        });
    };

    return (
        <div
            className={`toast toast-${type} -alert fade show ${className}`}
            role="alert"
            id={id}>
            <div className="d-flex flex-space-between">
                <div className="d-flex w-full">
                    {!hideIcon && (
                        <div>
                            <div className={`btn btn-icon btn-${type} mr-3`}>
                                {customIcon && customIcon}
                                {!customIcon && (
                                    <i
                                        className={`yh-new-icon-${icon} fs-18 ${
                                            type == 'secondary'
                                                ? '-text-color-grey-900'
                                                : ''
                                        }`}></i>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="flex grow flex-col md:flex-row">
                        <div className="d-flex flex-col justify-center">
                            {title && <p className="fw-bold mb-1">{title}</p>}
                            {text && <p className="m-0">{text}</p>}
                            {children && children}
                        </div>
                        {btn && (
                            <div className="grow flex items-center md:justify-end mt-2 md:!mt-0 md:ml-6">
                                {btn}
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div className="grow flex justify-end items-center ml-6 md:mr-6 ">
                            <Spinner size={20} />
                        </div>
                    )}
                </div>
                {dismissible && (
                    <button
                        type="button"
                        className="btn align-self-start p-0 ml-3"
                        onClick={() => hide()}>
                        <X size={20} weight="regular" />
                    </button>
                )}
            </div>
        </div>
    );
}
