import axios from 'axios';

import {
    FETCH_USER,
    SELECTED_ORDER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    TOGGLE_USER_CREDITS,
    UPDATE_USER_VOUCHER,
    UPDATE_SELECTED_FILTER,
} from '../constants/ActionTypes';

/* Fetch user success action */
export const fetchUserSuccess = (user) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: user,
    };
};

/* Fetch user error action */
export const fetchUserError = (error) => ({
    type: FETCH_USER_ERROR,
    payload: error,
});

/* Fetch dishes action */
export const fetchUser = () => async (dispatch, getState) => {
    try {
        const response = await axios.get('/user');
        dispatch(fetchUserSuccess(response));
        return Promise.resolve(response); // Resolve the promise with the fetched data
    } catch (err) {
        dispatch(fetchUserError(err.response));
        return Promise.reject(err); // Reject the promise with the error
    }
};

/* Toggles whether the user wants to use credits or not */
export const toggleUserCredits = () => ({
    type: TOGGLE_USER_CREDITS,
});

export const getUseableCredit = (price) => (dispatch, getState) => {
    const { userData } = getState();

    if (parseFloat(userData.total_credit) <= 0) return 0;

    if (parseFloat(price) >= parseFloat(userData.total_credit)) {
        return parseFloat(userData.total_credit);
    }

    return parseFloat(price);
};

export const getRemainingCredit = (price) => (dispatch, getState) => {
    const { userData } = getState();

    let useableCredit = dispatch(getUseableCredit(price));

    let creditRemaining = userData.total_credit - useableCredit;

    console.log({ useableCredit, creditRemaining });

    return parseFloat(creditRemaining);
};

export const isLoggedIn = () => {
    if (!window.isGuest) return true;

    return false;
};

/* Updates user voucher code if applied. */
export const updateUserVoucher = (voucher) => (dispatch) => {
    // Update reducer.
    dispatch({
        type: UPDATE_USER_VOUCHER,
        payload: voucher,
    });
};

export const setOrder = (orderId) => ({
    type: SELECTED_ORDER,
    payload: orderId,
});

/* Update selected filter action */
export const updateSelectedFilter = (value) => (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_FILTER,
        payload: value,
    });

    // setTimeout(function() {
    //     dispatch({
    //         type: UPDATE_SELECTED_FILTER_SUCCESS,
    //         payload: value,
    //     });
    // }.bind(this), 300)
};
