
class Debugger {
    
    paymentError = async (status) => {
        try {
            let url = `/error/debug`,
            data = {
                status,
                label: 'Payment Status'
            };

            const request = await axios.post(url, data)
        
        } catch (error) {
            console.error('Payment Error Debug')
        }
    }
}


export default new Debugger();